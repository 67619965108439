import context, { Constants } from '@truckdown/systems';
import { INavItem } from '../../INavItem';
import { defineComponent, Ref, ref, onMounted, computed } from 'vue';

const alwaysDisplay = function () {
    return true;
}

const csrOrAdminOnly = function () {
    return context.isInRole(Constants.Roles.SystemRole)  || context.isInRole(Constants.Roles.CSRRole);
}

const adminOnly = function () {
    return context.isInRole(Constants.Roles.SystemRole);
}

const getSubItem = function (name: string, icon: string, url: string, display?: () => boolean, tooltip?: string, id?: string): ISelectableItem {
    return {
        id: id ?? name,
        name: name,
        icon: icon,
        url: url,
        tooltip: tooltip,
        active: false,
        display: display ?? alwaysDisplay,
        subItems: []
    };
}

const getItems = function () {
    return [
        {
            name: 'Account Administration', icon: 'faUserGear', active: false, subItems: [
                getSubItem('Account Settings', 'faUser', '/settings/user/settings'),
                getSubItem('Billing', 'faFileInvoice', '/billing'),
                getSubItem('Team Management', 'faUsers', '/settings/user/administration'),
                getSubItem('Integrations', 'faKey', '/security/apikeys')
            ], display: alwaysDisplay
        },
        {
            name: 'Advertising Services', icon: 'faBriefcase', active: false, subItems: [
                getSubItem('Listings', 'faLocationDot', '/listings'),
                getSubItem('Promotions', 'faMoneyBill', '/promotions'),
                getSubItem('Banner Ads', 'faRectangleAd', '/banners'),
                getSubItem('Ratings', 'faFaceSmile', '/vendor-ratings', alwaysDisplay, undefined, 'Vendor Ratings')
            ], display: alwaysDisplay
        },
        {
            name: 'Fleet / Driver Services', icon: 'faTruckFront', active: false, subItems: [
                getSubItem('Search & Call History', 'faHistory', '/search/call-history'),
                getSubItem('Ratings', 'faFaceSmile', '/user-ratings', alwaysDisplay, undefined, 'User Ratings'),
                getSubItem('Notes', 'faPenToSquare', '/listings/notes'),
                getSubItem('Favorite Vendors', 'faThumbsUp', '/listings/favorites'),
                getSubItem('Avoided Vendors', 'faThumbsDown', '/listings/avoided'),
                getSubItem('Routing', 'faRoute', '/route')
            ], display: alwaysDisplay
        },
        {
            name: 'System Administration', icon: 'faGears', active: false, subItems: [
                getSubItem('Analytics', 'faChartLine', '/analytics/admin', csrOrAdminOnly),
                getSubItem('Coverage Analysis', 'faGlobe', '/lsearch/analyze-coverage/admin', csrOrAdminOnly),
                getSubItem('Reports', 'faChartSimple', '/report/admin-reports', csrOrAdminOnly),
                getSubItem('Direct Mail List Admin', 'faEnvelope', '/security/direct-mail-lists', adminOnly),
                getSubItem('IP/User Restrictions', 'faKey', '/tools-apps/restrictions-admin', adminOnly),
                getSubItem('Notes Management', 'faPenToSquare', '/listings/notes/admin', adminOnly),
                getSubItem('City Management', 'faCity', '/tools-apps/cities', adminOnly),
                getSubItem('Services', 'faWrench', '/tools-apps/services', adminOnly),
                getSubItem('Holiday Management', 'faCalendar', '/security/admin/holidays', adminOnly),
                getSubItem('CSR Admin', 'faPeopleGroup', '/tools-apps/csrs', adminOnly),
                getSubItem('Throttles Admin', 'faLock', '/tools-apps/throttles-admin', adminOnly),
                getSubItem('Newsletters', 'faNewspaper', '/newsletter', adminOnly),
                getSubItem('System Tools', 'faGear', '/system', adminOnly),
                getSubItem('System Logs', 'faServer', '/systems/logging', adminOnly),
                getSubItem('Database Admin', 'faDatabase', '/mongo/admin', adminOnly),
                getSubItem('Jobs Admin', 'faPlay', '/tools-apps/job-processes', adminOnly)
            ], display: csrOrAdminOnly
        }
    ];
};

interface IItem {
    id?: string;
    name: string;
    tooltip?: string;
    icon: string;
    active: boolean;
    subItems: ISelectableItem[];
    display: () => boolean;
}

interface ISelectableItem extends INavItem {
    id: string;
    subItems: ISelectableItem[];
    active: boolean;
}

interface IData {
    hideNav: Ref<boolean>,
    items: Ref<IItem[]>,
    shownSubMenus: Ref<string[]>
};

interface IProps {
    openSection: string,
    activeItem: string,
    subSection: string,
    subItems: INavItem[]
}

const getSection = function (name: string, items: IItem[]) : IItem | ISelectableItem | null {
    for (let i = 0; i < items.length; i++) {
        var itm = items[i];
        if (itm.id == name || itm.name == name) {
            return itm;
        }

        for (let i2 = 0; i2 < itm.subItems.length; i2++) {
            var subItm = itm.subItems[i2];
            
            if (subItm.id == name) {
                return subItm;
            }

            for (let i3 = 0; i3 < subItm.subItems.length; i3++) {
                var final = subItm.subItems[i3];
            
                if (final.id == name) {
                    return final;
                }
            }
        }
    }
    return null;
}

const setMenuOptions = function (props: IProps, items: IItem[]) {

    if (props.subSection.length > 0 && props.subItems.length > 0) {
        let section = getSection(props.subSection, items);
        if (section != null) {
            section.subItems = props.subItems.map((itm) => {
                return {
                    id: itm.id ?? itm.name,
                    name: itm.name,
                    tooltip: itm.tooltip,
                    icon: itm.icon,
                    url: itm.url,
                    display: alwaysDisplay,
                    active: false,
                    subItems: []
                } as ISelectableItem 
            });
        }
    }

    if (props.activeItem.length > 0) {
        let activeSection = getSection(props.activeItem, items);
        if (activeSection != null) {
            activeSection.active = true;
        }
    }
}

const getNavHideStatus = function () {
    var queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('hsn')) {
        return true;
    }

    return window.innerWidth < 525;
}

const getShownMenus = function (props: IProps) {
    var start = csrOrAdminOnly() ? [] : [
        context.settings['accounttype'] == 'Vendor' ? 'Advertising Services' : 'Fleet / Driver Services'
    ];

    if (props.openSection.length > 0 && !start.some(s => s == props.openSection)) {
        start.push(props.openSection);
    }

    return start;
}

const getData = function (props: IProps): IData {
    var items = getItems();
    let shownMenus = getShownMenus(props);
    setMenuOptions(props, items);

    return {
        hideNav: ref<boolean>(getNavHideStatus()),
        items: ref<IItem[]>(items),
        shownSubMenus: ref<string[]>(shownMenus)
    }
}

const getMethods = function (data: IData) {
    return {
        toggleNav: function (ev: Event) {
            data.hideNav.value = !data.hideNav.value;
            var queryParams = new URLSearchParams(window.location.search);
            if (data.hideNav.value) {
                queryParams.set('hsn', '1');
                history.replaceState(null, '', '?' + queryParams.toString());
            } else {
                if (queryParams.has('hsn')) {
                    queryParams.delete('hsn');
                    var newSearch = queryParams.toString();
                    if (newSearch.length == 0) {
                        history.replaceState(null, '', location.pathname);
                    } else {
                        history.replaceState(null, '', '?' + queryParams.toString());
                    }
                }
            }
        },
        toggleMenu: function (name: string) {
            if (data.shownSubMenus.value.some(v => v == name)) {
                data.shownSubMenus.value = data.shownSubMenus.value.filter(v => v != name);
                return true;
            } else {
                data.shownSubMenus.value.push(name);
            }
        },
        shouldShowMenu: function (name: string) {
            if (data.shownSubMenus.value.some(v => v == name)) {
                return true;
            }
            return false;
        },
        getUrl: function (url: string) {
            if (data.hideNav.value) {
                var hasQuery = url.indexOf('?') > 0;
                if (hasQuery) {
                    return url + '&hsn=1';
                } else {
                    return url + '?hsn=1';
                }
            }

            return url;
        }
    };
}

export default defineComponent({
    name: 'SideNav',
    props: {
        openSection: {
            type: String,
            default: ''
        },
        activeItem: {
            type: String,
            default: ''
        },
        subSection: {
            type: String,
            default: ''
        },
        subItems: {
            type: Array as () => INavItem[],
            default: []
        }
    },
    setup: function (props) {
        let data = getData(props as IProps);

        let methods = getMethods(data);

        return {
            ...data,
            ...methods
        };
    }
});
