<template>
    <div class="cmp-promotion section">
        <div class="content">
            <div class="promo-listing">
                <div class="message">
                    <h3>{{ listing.name }}</h3>
                    <h4>{{ title }}</h4>
                    <p><icon type="solid" name="faCircleInfo" aria-hidden="true"></icon><strong> {{ message }}<a v-if="displayMoreMessage" class="more" href="#" @click.prevent="showMessage">... more</a></strong></p>
                    <p v-if="subMessage.length > 0" class="td-text-mice submessage">{{ subMessage }}<a v-if="displayMoreSubMessage" class="more" href="#" @click.prevent="showSubMessage">... more</a></p>
                    <a v-if="!isModal && !showEdit" class="td-btn" :href="'#' + listing.id" v-smooth-scroll="{ offset: -50 }" @click="view">View Listing</a>
                    <a v-if="showEdit" class="td-btn" @click="edit">Edit Promotion</a>
                </div> 
                <div class="address">
                    <hours-status :hours="listing.hours" :is-shop="!listing.properties.mobileOnly && !listing.properties.managedService" class="mt-1"></hours-status>
                    {{ listing.location.line1 }}<br />
                    {{ listing.location.line2 }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>import script from './PromotionComponent';
    export default script;</script>

<style lang="less">
    @import "./PromotionComponent.less";
</style>
