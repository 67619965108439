<template>
    <div class="modal-mask" v-on:click="close">
        <div class="modal-container" v-bind="$attrs" @click="preventClickThrough">
            <icon v-if="showCloseIcon" aria-hidden="true" class="secondary x-close" @click.prevent="close" type="solid" name="faCircleXmark"></icon>
            <div v-if="slots.header" class="modal-header">
                <slot name="header"></slot>
            </div>
            <div class="modal-body">
                <slot></slot>
            </div>
            <div v-if="showFooter" class="modal-footer">
                <slot name="footer">
                    <button class="td-btn secondary-outlined" title="Close" @click="close">Close</button>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>import script from './Modal';
    export default script;</script>

<style lang="less">
    @import "./Modal.less";
</style>
