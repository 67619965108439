import context from '@truckdown/components-systems';
import { ILocationActionsService, Listing, PromotionModel } from '@truckdown/locations';
import { defineComponent, ref, Ref, computed, onMounted } from 'vue';
import { IListingService } from '../../Services/IListingService';

interface IData {
    showFullMessage: Ref<boolean>,
    showFullSubMessage: Ref<boolean>
}

interface IProps {
    promotion: PromotionModel,
    listing: Listing,
    isModal: boolean,
    showEdit: boolean
}

const getInitialData = function (props: any) {
    return {
        showFullMessage: ref<boolean>(false),
        showFullSubMessage: ref<boolean>(false)
    } as IData;
}

const getMethods = function (props: IProps, data: IData, ctx: any) {
    let hasbeenviewed = false;

    return {
        view: function (ev: Event) {
            if (!hasbeenviewed) {
                hasbeenviewed = true;
                context.getService<IListingService>('IListingService')
                    .then((service) => {
                        return service.viewListingFromPromotion(props.promotion.id, props.promotion.locationId)
                            .then(() => {
                                context.analytics({
                                    'event': 'Promotion',
                                    'event_category': 'Listing',
                                    'event_label': 'Show'
                                });
                            });
                    })
                    .catch((reason) => {
                        console.log('Error viewing location from promotion', reason);
                    });
            }
        },
        edit: function (ev: Event) {
            ctx.emit(editPromotionEventName);
        },
        showMessage: function (ev: Event) {
            data.showFullMessage.value = true;
        },
        showSubMessage: function (ev: Event) {
            data.showFullSubMessage.value = true;
        }
    }
}

const getMessage = function (message: string) {
    let idx = message.lastIndexOf('*');
    if (idx >= 0) {
        return message.substring(0, idx);
    }
    return message;
}

const getSubMessage = function (message: string) {
    let idx = message.lastIndexOf('*');
    if (idx >= 0) {
        return message.substring(idx, message.length);
    }
    return '';
}

const getComputed = function (props: IProps, data: IData) {
    return {
        title: computed(() => {
            return props.promotion.title;
        }),
        message: computed(() => {
            var msg = getMessage(props.promotion.message);
            if (props.isModal || msg.length <= 175 || data.showFullMessage.value) {
                return msg;
            }
            return msg.substring(0, 175);
        }),
        subMessage: computed(() => {
            var msg = getSubMessage(props.promotion.message);
            if (props.isModal || msg.length <= 175 || data.showFullSubMessage.value) {
                return msg;
            }
            return msg.substring(0, 175);
        }),
        displayMoreMessage: computed(() => {
            var msg = getMessage(props.promotion.message);
            if (props.isModal || msg.length <= 175 || data.showFullMessage.value) {
                return false;
            } else {
                return true;
            }
        }),
        displayMoreSubMessage: computed(() => {
            var msg = getSubMessage(props.promotion.message);
            if (props.isModal || msg.length <= 175 || data.showFullSubMessage.value) {
                return false;
            } else {
                return true;
            }
        })
    };
}

const editPromotionEventName = 'edit-promotion';

export default defineComponent({
    props: {
        promotion: {
            type: Object as () => PromotionModel,
            required: true
        },
        listing: {
            type: Object as () => Listing,
            required: true
        },
        isModal: {
            type: Boolean,
            required: false,
            default: false
        },
        showEdit: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: [editPromotionEventName],
    setup: function (props, ctx) {
        let data = getInitialData(props);
        let computed = getComputed(props, data);
        let methods = getMethods(props, data, ctx);

        return {
            ...data,
            ...computed,
            ...methods
        };
    }
});
