<template>
    <nav :class="['td-sidenav', {'td-sidenav-hidden' : hideNav}]">
        <div class="nav-items">
            <template v-for="item in items" :key="item.name">
                <div v-if="item.display()" :class="['nav-item', {'active': item.active}]">
                    <a href="#" class="main-item item" @click.prevent="toggleMenu(item.name)" :title="item.tooltip ?? item.name" :aria-label="item.tooltip ?? item.name">
                        <div class="item-icon">
                            <icon :name="item.icon" type="solid"></icon>
                        </div>
                        <div class="item-info">
                            <h5>{{ item.name }}</h5>
                        </div>
                    </a>
                </div>
                <div v-if="shouldShowMenu(item.name) && item.subItems.length > 0" class="sub-items">
                    <template v-for="subItem in item.subItems" :key="subItem.name">
                        <div v-if="subItem.display()" :class="['nav-item', {'active': subItem.active}, {'parent':subItem.display() && subItem.subItems.length > 0}]">
                            <a :href="getUrl(subItem.url)" class="item" :title="subItem.tooltip ?? subItem.name" :aria-label="subItem.tooltip ?? subItem.name">
                                <div class="item-icon">
                                    <icon :name="subItem.icon" type="solid"></icon>
                                </div>
                                <div class="item-info">
                                    <h5>{{ subItem.name }}</h5>
                                </div>
                            </a>
                        </div>

                        <div v-if="subItem.display() && subItem.subItems.length > 0" class="sub-items">
                            <template v-for="final in subItem.subItems" :key="final.name">
                                <div v-if="final.display()" :class="['nav-item', {'active': final.active}]">
                                    <a :href="getUrl(final.url)" class="item" :title="final.tooltip ?? final.name" :aria-label="final.tooltip ?? final.name">
                                        <div class="item-icon">
                                            <icon :name="final.icon" type="solid"></icon>
                                        </div>
                                        <div class="item-info">
                                            <h5>{{ final.name }}</h5>
                                        </div>
                                    </a>
                                </div>
                            </template>
                        </div>
                    </template>
                </div>
            </template>
        </div>
        <span v-if="!hideNav" @click.prevent="toggleNav" class="toggle-icon">
            <icon name="faAngleLeft" type="solid"></icon>
        </span>
        <span v-if="hideNav" @click.prevent="toggleNav" class="toggle-icon toggle-mob">
            <icon name="faAngleRight" type="solid"></icon>
        </span>
    </nav>
</template>

<script>
import script from './SideNav';
    export default script;</script>

<style lang="less">
    @import "./SideNav.less";
</style>