import context, { IconType, IIconImport } from '@truckdown/components-systems';
import registerLocations from '@truckdown/locations';
import components from '@truckdown/components-locations';
import { IInitializer, IUserContext } from '@truckdown/systems';

registerLocations(context);
components.registerPrerequisites(context);

const importCC = async function (icons: IIconImport) {

    var definition = ((await import(
        /* webpackChunkName: "icons/sol/faCreditCard" */
        '../../Icons/Solid/faCreditCard.cjs') as any).definition) as any;
    icons.registerIcon('faCreditCard', IconType.Solid, definition);
}

context.registerInitializer({
    order: 0,
    async initialize(ctx: IUserContext) {
        return ctx.getService<IIconImport>('IIconImport')
            .then(async (service) => {
                await importCC(service);
            });
    }
} as IInitializer);


context.initialize().then(() => {
    (window as any).isSysInitialized = true;
    console.log('System initialized');
});