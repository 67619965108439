<template>
    <div v-click-outside="closeMenu" class="side-menu">
        <icon @click.prevent="toggleMenu" type="solid" name="faBars" aria-hidden="true" focusable="false" class="nav-icon" title="Menu"></icon>
        <transition name="layoutdropdown">
            <div class="menu-list" v-if="showMenu">
                <a v-if="!isPlanRV" class="item" href="/" title="Find heavy-duty truck services anywhere in the US or Canada" aria-label="Search">
                    Search<span class="title">Find heavy-duty truck services anywhere in the US or Canada</span>
                </a>
                <a v-if="!isPlanRV" class="item" href="/route" title="Create truck-legal routes, view route reports, and manage your IFTA fuel tax reporting information" aria-label="Routing">
                    Routing<span class="title">Create truck-legal routes, view route reports, and manage your IFTA fuel tax reporting information</span>
                </a>
                <a v-if="!isPlanRV" class="item" href="/shop/fleets" title="Minimize your fleet maintenance costs with TruckDown services" aria-label="Fleets">
                    Fleets<span class="title">Minimize your fleet maintenance costs with TruckDown services</span>
                </a>
                <a v-if="!isPlanRV" class="item" href="/shop/advertise" title="Advertise your business on TruckDown" aria-label="Advertise">
                    Advertise<span class="title">Advertise your business on TruckDown</span>
                </a>
                <a v-if="!isPlanRV" class="item" href="/admin" title="Access and Manage all of TruckDown\'s products & services" aria-label="Admin">
                    Admin<span class="title">Access and Manage all of TruckDown's products & services</span>
                </a>

                <a v-if="isPlanRV" class="item" href="/" title="Find RV Services, Campgrounds, and Rest Areas anywhere in the US or Canada" aria-label="Search">
                    Search<span class="title">Find RV Services, Campgrounds, and Rest Areas anywhere in the US or Canada</span>
                </a>
                <a v-if="isPlanRV" class="item" href="/route" title="Create and search along routes to help plan your trip" aria-label="Routing">
                    Routing<span class="title">Create and search along routes to help plan your trip</span>
                </a>
                <a v-if="isPlanRV" class="item" href="/shop/features" title="Minize your RV maintenance costs with PlanRV services" aria-label="For RVs">
                    For RVs<span class="title">Minize your RV maintenance costs with PlanRV services</span>
                </a>
                <a v-if="isPlanRV" class="item" href="/shop/advertise" title="Advertise your business on PlanRV" aria-label="Advertise">
                    Advertise<span class="title">Advertise your business on PlanRV</span>
                </a>
                <a v-if="isPlanRV" class="item" href="/admin" title="Access and Manage all of PlanRV\'s products & services" aria-label="Admin">
                    Admin<span class="title">Access and Manage all of PlanRV's products & services</span>
                </a>

                <a v-if="isAdmin" @click.prevent="toggleControlPanel" class="item" href="#" title="Find accounts, log in as users, and access tools to help support customers" aria-label="Control Panel">
                    Control Panel<span class="title">Find accounts, log in as users, and access tools to help support customers</span>
                </a>
                <a v-if="isAdmin" class="item" href="/task-manager" title="Manage your tasks" aria-label="Task Manager">
                    Task Manager<span class="title">Manage your tasks</span>
                </a>

                <a v-if="isAuthenticated && hasRealUsername" class="item" href="/account/log-revert" title="Revert to your original accoumt" aria-label="Revert">
                    Revert <span class="title">Revert to your original account</span>
                </a>
                <a v-if="isAuthenticated" class="item" href="/accounts/log-off" title="Log off your account" aria-label="Sign Out">
                    Sign Out<span class="title">Log off your account</span>
                </a>
                <a v-if="!isAuthenticated" class="item" href="/accounts/create-or-signin" title="Register a New Account" aria-label="Register">
                    Register<span class="title">Register a New Account</span>
                </a>
                <a v-if="!isAuthenticated" class="item" href="/accounts/create-or-signin" title="Log into your existing account" aria-label="Sign In">
                    Sign In<span class="title">Log into your existing account</span>
                </a>

                <a class="td-text-right item" href="#" @click.prevent="toggleContext" title="View context information" aria-label="View context information">
                    <icon type="solid" name="faIdCard" aria-hidden="true"></icon>
                </a>
            </div>
        </transition>

        <transition name="modal">
            <modal v-if="showContext" @close="closeContext">
                <context-info></context-info>
            </modal>
        </transition>
        <transition name="modal">
            <modal v-if="showControlPanel" @close="closeControlPanel" hide-footer hide-close class="td-admin-search-modal">
                <control-panel :searchText="inputString" @close="closeControlPanel"></control-panel>
            </modal>
        </transition>
    </div>
</template>

<script>import script from './HeaderMenu';
    export default script;</script>

<style lang="less">
    @import "./HeaderMenu.less";
</style>
